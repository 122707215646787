import xlsx from 'xlsx'

export const xlsxMixins = {
  methods: {
    exportToXLS (filename, data) {
      const cloneObject = [...data]
      cloneObject.forEach(element => {
        for (var key in element) {
          if (Array.isArray(element[key])) {
            let mergeStr = ''
            element[key].forEach(subElement => {
              mergeStr += subElement.title + ','
            })
            element[key] = mergeStr
          } else if (!element[key]) {
            element[key] = ''
          } else if (typeof element[key] === 'object') {
            element[key] = element[key].title
          }
        }
      })
      const dataWS = xlsx.utils.json_to_sheet(cloneObject, { dateNF: 'dd/mm/yyyy HH:MM:ss' })
      const wb = xlsx.utils.book_new()
      xlsx.utils.book_append_sheet(wb, dataWS)
      xlsx.writeFile(wb, filename + '.xlsx')
    }
  }
}
