<template>
   <v-container fluid>
    <TitleBar :title="'ผลลัพธ์แบบสำรวจ'" :subtitle="''">
      <template slot="action">

            <v-btn large color="primary" :loading="exporting" @click="exToXLS" outlined><v-icon>mdi-export-variant</v-icon><span class="text-title-2">ทั้งหมด</span></v-btn>

            <v-btn large color="primary" class="ml-2" @click="exportToXLS('monitor', items)" outlined><v-icon>mdi-export-variant</v-icon>เฉพาะส่วนที่แสดงผล</v-btn>
      </template>
    </TitleBar>
      <FilterBar>
      <template v-slot:left>
        <v-col cols="12" md="2" >
                  <DatePicker :title="'ตั้งแต่วันที่'" :placeholder="'โปรดเลือก'" v-model="startDate"/>
              </v-col>
               <v-col cols="12" md="2" >
                <DatePicker :title="'ถึงวันที่'" :placeholder="'โปรดเลือก'" v-model="endDate"/>
              </v-col>
         <!-- <v-col cols="12" md="2" >
          <TextField v-model="search" :title="'ค้นหา'" append-icon="mdi-magnify" placeholder="ชื่อ,ผู้สร้าง,อื่นๆ"/>
         </v-col> -->
      </template>
      <template v-slot:right>
               <v-col cols="12" md="3" >
                <Select :title="'ชื่อฟอร์ม'" :placeholder="'โปรดเลือก'" :items="formListItems" v-model="formId" item-text="name" return-object/>
              </v-col>
              <v-col cols="12" md="3">
                <v-form ref="form" v-model="valid">
                <TextField :title="'คำค้นหา'" :placeholder="'ชื่อ หรือ เลขประจำตัวประชาชน'" clearable v-model="keyword" :rules="rules"/>
              </v-form>
              </v-col>
              <v-col cols="12" md="2" >
    <v-btn large color="primary" class="mt-6" @click="getResult" :disabled="dataLoading"><span class="text-title-2">เรียกดูข้อมูล</span></v-btn>
    </v-col>
    </template>
    </FilterBar>
    <DataTable
    :server-items-length="totalItems"
    :options.sync="options" :headers="headers"
    :loading="dataLoading"
    :search="search"
    :items="items"
    :footer-props="{
    'items-per-page-options': [50, 100, 500, 5000]
  }"
    >
       <template v-for="objectColumn in objectColumns" v-slot:[`item.${objectColumn.value}`]="{ item }">
    <div :key="objectColumn.value">
         {{ (Array.isArray(item[objectColumn.value]))? item[objectColumn.value].map(obj =>  (typeof obj === 'object')? obj.title : obj): (typeof item[objectColumn.value] === 'object')?item[objectColumn.value].title : item[objectColumn.value] }}
          <!-- {{(typeof item[objectColumn.value] === 'object')? item[objectColumn.value].title : item[objectColumn.value]}} -->
    </div>
      </template>
        <template v-for="numberColumn in numberColumns" v-slot:[`item.${numberColumn.value}`]="{ item }">
    <div :key="numberColumn.value">
      <v-chip :color="getAlertColor(numberColumn,item[numberColumn.value])">
          {{item[numberColumn.value]}}
      </v-chip>
    </div>
      </template>
      <template v-slot:item.createDate="{item}">
        {{$dayjs(new Date(item.createDate) ).locale('th').format('DD MMMM BBBB HH:mm')}}
      </template>
       <template v-for="locationColumn in locationColumns" v-slot:[`item.${locationColumn.value}`]="{ item }">
    <div :key="locationColumn.value">
<v-btn icon v-if="item[locationColumn.value]" @click="getMap(item[locationColumn.value])"><v-icon>mdi-google-maps</v-icon></v-btn>
    </div>
      </template>
 <template v-for="imageColumn in imageColumns" v-slot:[`item.${imageColumn.value}`]="{ item }">
    <div :key="imageColumn.value">
<v-btn icon @click="getImage(item[imageColumn.value])"><v-icon>mdi-file-image</v-icon></v-btn>
    </div>
      </template>
       <template v-slot:item.action="{ item }">
          <v-btn icon @click="getFormResultItem(item)">
            <v-icon>mdi-pencil</v-icon>
         </v-btn>
         <v-btn icon @click="deleteItem(item)">
            <v-icon>mdi-delete</v-icon>
         </v-btn>
       </template>
    </DataTable>
      <v-dialog v-model="deleteDialog" width="384" persistent>
        <ConfirmDeleteCard :title="'คุณต้องการลบรายการนี้หรือไม่'" :subtitle="'หากคุณทำการลบรายการที่คุณทำไว้จะหายไปและจะต้องทำรายการใหม่อีกครั้ง'" :action="'ลบข้อมูลนี้'" @onClose="deleteDialog=false" @onConfirm="deleteFormResult()" />
        </v-dialog>
    <v-dialog style="z-index: 9998" v-model="locationDialog" persistent scrollable fullscreen>
        <MapCard @onClose="locationDialog=false" :lat="Number(lat)" :long="Number(long)" />
      </v-dialog>
        <v-dialog style="z-index: 9998" v-model="imageDialog" persistent scrollable fullscreen>
        <ImageCard @onClose="imageDialog=false" :data="image" :formId="formId" :key="imageDialog" />
      </v-dialog>
       <v-dialog style="z-index: 9998" v-model="preview" width="384" persistent scrollable >
        <PreviewCard @onClose="preview=false" @onConfirm="patchFormResult"/>
      </v-dialog>
     <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-container>
</template>

<script>
import TitleBar from '@/components/common/TitleBar'
// import TextField from '@/components/common/TextField'
// import TextField from '@/components/common/TextField'
import FilterBar from '@/components/common/FilterBar'
import DataTable from '@/components/common/DataTable'
import Select from '@/components/common/Select'
import DatePicker from '@/components/common/DatePicker'
import TextField from '@/components/common/TextField'
import { getFormResult, getYear, getSiteFormType, deleteFormResult, getFormResultItem, patchFormResult, getForm } from '@/api/' // getFormResult // getForm
import { mapFields } from 'vuex-map-fields'
import { xlsxMixins } from '@/mixins/xlsx'

export default {
  mixins: [xlsxMixins],
  components: {
    TitleBar,
    // TextField,
    TextField,
    FilterBar,
    DataTable,
    Select,
    DatePicker,
    ConfirmDeleteCard: () => import('@/components/common/ConfirmDeleteCard'),
    MapCard: () => import('@/components/monitor/MapCard'),
    ImageCard: () => import('@/components/monitor/ImageCard'),
    PreviewCard: () => import('@/components/form/EditCard')
  },
  data () {
    return {
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      items: [],
      headers: [],
      loading: false,
      dataLoading: false,
      deleteDialog: false,
      search: null,
      yearItems: [],
      formTypeItems: [],
      formListItems: [],
      formId: null,
      formType: null,
      year: null,
      lat: 0,
      long: 0,
      image: null,
      locationDialog: false,
      imageDialog: false,
      currentItem: null,
      preview: false,
      totalItems: 0,
      options: {},
      exporting: false,
      keyword: '',
      // eslint-disable-next-line no-useless-escape
      rules: [v => !/[!@$^&_\[\]{};'\\|?]+/.test(v) || 'ไม่รองรับอักขระพิเศษ'],
      valid: true
    }
  },
  computed: {
    ...mapFields(['result', 'form']),
    objectColumns () {
      return this.headers.filter(val => val.type === 'select')
    },
    numberColumns () {
      return this.headers.filter(val => val.inputType === 'number')
    },
    locationColumns () {
      return this.headers.filter(val => val.type === 'location')
    },
    imageColumns () {
      return this.headers.filter(val => val.type === 'image')
    }
  },
  watch: {
    options: {
      handler () {
        this.getResult()
      },
      deep: true
    }
    // year (val) {
    //   if (!val) return
    //   const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
    //   const site = JSON.parse(sessionStorage.getItem('Site'))
    //   this.dataLoading = true
    //   getForm({
    //     tenantId: tenant.tenantId,
    //     siteId: site.siteId,
    //     offset: 0,
    //     limit: 100,
    //     formTypeId: this.buildParam('formTypeId', [this.formType]),
    //     year: this.buildParam('year', [val]),
    //     active: this.buildParam('active', [true, false])
    //   }, message => {
    //     if (message.data.code === 1) {
    //       if (message.data.result) {
    //         this.formListItems = message.data.result.items
    //       } else {
    //         this.formListItems = []
    //       }
    //     }
    //     this.dataLoading = false
    //   }, error => {
    //     this.dataLoading = false
    //     console.log(error)
    //   })
    // },
    // formType (val) {
    //   if (!val) return
    //   const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
    //   const site = JSON.parse(sessionStorage.getItem('Site'))
    //   this.dataLoading = true
    //   getForm({
    //     tenantId: tenant.tenantId,
    //     siteId: site.siteId,
    //     offset: 0,
    //     limit: 100,
    //     formTypeId: this.buildParam('formTypeId', [val]),
    //     year: this.buildParam('year', [this.year]),
    //     active: this.buildParam('active', [true, false])
    //   }, message => {
    //     if (message.data.code === 1) {
    //       if (message.data.result) {
    //         this.formListItems = message.data.result.items
    //       } else {
    //         this.formListItems = []
    //       }
    //     }
    //     this.dataLoading = false
    //   }, error => {
    //     this.dataLoading = false
    //     console.log(error)
    //   })
    // },
    // formId (val) {
    //   if (val.length > 0) {
    //     this.dataLoading = true
    //     const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
    //     const site = JSON.parse(sessionStorage.getItem('Site'))
    //     const { sortBy, sortDesc, page, itemsPerPage } = this.options
    //     console.log('formId change')
    //     if (this.formListItems.length > 0) {
    //       getFormResult({ tenantId: tenant.tenantId, siteId: site.siteId, formTypeId: this.formType, year: this.buildParam('year', [this.year]), name: '', offset: (itemsPerPage * (page - 1)), limit: itemsPerPage, sortBy: sortBy, sortDesc: sortDesc, formId: this.buildParam('formId', val) }, message => {
    //         if (message.data.code === 1) {
    //           this.items = message.data.result.items
    //           this.totalItems = message.data.result.itemsLength
    //           message.data.result.headers = message.data.result.headers.map(this.getHeader)
    //           message.data.result.headers.push({ text: 'ดำเนินการ', value: 'action', class: 'primary lighten-5 fixed', width: 150, align: 'center fixed' })
    //           this.headers = message.data.result.headers
    //         }
    //         this.dataLoading = false
    //       }, error => {
    //         this.dataLoading = false
    //         console.log(error)
    //       })
    //     } else {
    //       this.items = []
    //       this.headers = []
    //     }
    //   }
    // }
  },
  methods: {
    getForm () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      this.dataLoading = true
      getForm({
        tenantId: tenant.tenantId,
        siteId: site.siteId,
        offset: 0,
        limit: 100,
        formTypeId: this.buildParam('formTypeId', ['']),
        year: this.buildParam('year', ['']),
        active: this.buildParam('active', [true])
      }, message => {
        if (message.data.code === 1) {
          if (message.data.result) {
            this.formListItems = message.data.result.items
            this.formId = message.data.result.items[0]
          } else {
            this.formListItems = []
          }
        }
        this.dataLoading = false
      }, error => {
        this.dataLoading = false
        console.log(error)
      })
    },
    getResult () {
      if (this.$refs.form.validate()) {
        this.dataLoading = true
        const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
        const site = JSON.parse(sessionStorage.getItem('Site'))
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (this.formListItems.length > 0) {
          getFormResult({ tenantId: tenant.tenantId, siteId: site.siteId, formTypeId: this.formId.formTypeId, year: this.buildParam('year', ['']), name: '', offset: (itemsPerPage * (page - 1)), limit: itemsPerPage, sortBy: sortBy, sortDesc: sortDesc, formId: this.buildParam('formId', [this.formId.formId]), startDate: this.startDate, endDate: this.endDate, keyword: (this.keyword) ? this.keyword : '' }, message => {
            if (message.data.code === 1) {
              this.items = message.data.result.items
              this.totalItems = message.data.result.itemsLength
              message.data.result.headers = message.data.result.headers.map(this.getHeader)
              message.data.result.headers.push({ text: 'ดำเนินการ', value: 'action', class: 'primary lighten-5 fixed', width: 150, align: 'center fixed' })
              this.headers = message.data.result.headers
            }
            this.dataLoading = false
          }, error => {
            this.dataLoading = false
            console.log(error)
          })
        } else {
          this.items = []
          this.headers = []
        }
      }
    },
    exToXLS () {
      this.exporting = true
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      if (this.formListItems.length > 0) {
        getFormResult({ tenantId: tenant.tenantId, siteId: site.siteId, formTypeId: this.formId.formTypeId, year: this.buildParam('year', ['']), name: '', offset: 0, limit: 10000, sortBy: '', sortDesc: '', formId: this.buildParam('formId', [this.formId.formId]), startDate: this.startDate, endDate: this.endDate }, message => {
          if (message.data.code === 1) {
            this.exportToXLS('monitor', message.data.result.items)
            // this.items = message.data.result.items
            // this.totalItems = message.data.result.itemsLength
            // message.data.result.headers = message.data.result.headers.map(this.getHeader)
            // message.data.result.headers.push({ text: 'ดำเนินการ', value: 'action', class: 'primary lighten-5 fixed', width: 150, align: 'center fixed' })
            // this.headers = message.data.result.headers
          }
          this.exporting = false
        }, error => {
          this.exporting = false
          console.log(error)
        })
      }
      //
    },
    applyFooWatcher () {
      // this.$watch('year', function (val) {
      //   if (!val) return
      //   const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      //   const site = JSON.parse(sessionStorage.getItem('Site'))
      //   this.dataLoading = true
      //   getForm({
      //     tenantId: tenant.tenantId,
      //     siteId: site.siteId,
      //     offset: 0,
      //     limit: 100,
      //     formTypeId: this.buildParam('formTypeId', [this.formType]),
      //     year: this.buildParam('year', [val]),
      //     active: this.buildParam('active', [true, false])
      //   }, message => {
      //     if (message.data.code === 1) {
      //       if (message.data.result) {
      //         this.formListItems = message.data.result.items
      //       } else {
      //         this.formListItems = []
      //       }
      //     }
      //     this.dataLoading = false
      //   }, error => {
      //     this.dataLoading = false
      //     console.log(error)
      //   })
      // })
      // this.$watch('formType',
      //   function (val) {
      //     if (!val) return
      //     const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      //     const site = JSON.parse(sessionStorage.getItem('Site'))
      //     this.dataLoading = true
      //     getForm({
      //       tenantId: tenant.tenantId,
      //       siteId: site.siteId,
      //       offset: 0,
      //       limit: 100,
      //       formTypeId: this.buildParam('formTypeId', [val]),
      //       year: this.buildParam('year', [this.year]),
      //       active: this.buildParam('active', [true, false])
      //     }, message => {
      //       if (message.data.code === 1) {
      //         if (message.data.result) {
      //           this.formListItems = message.data.result.items
      //         } else {
      //           this.formListItems = []
      //         }
      //       }
      //       this.dataLoading = false
      //     }, error => {
      //       this.dataLoading = false
      //       console.log(error)
      //     })
      //   })

      // this.$watch('formId',
      //   function (val) {
      //     if (val.length > 0) {
      //       this.dataLoading = true
      //       const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      //       const site = JSON.parse(sessionStorage.getItem('Site'))
      //       const { sortBy, sortDesc, page, itemsPerPage } = this.options
      //       console.log(this.options)
      //       if (this.formListItems.length > 0) {
      //         getFormResult({ tenantId: tenant.tenantId, siteId: site.siteId, formTypeId: this.formType, year: this.buildParam('year', [this.year]), name: '', offset: (itemsPerPage * (page - 1)), limit: itemsPerPage, sortBy: sortBy, sortDesc: sortDesc, formId: this.buildParam('formId', val) }, message => {
      //           if (message.data.code === 1) {
      //             this.items = message.data.result.items
      //             this.totalItems = message.data.result.itemsLength
      //             message.data.result.headers = message.data.result.headers.map(this.getHeader)
      //             message.data.result.headers.push({ text: 'ดำเนินการ', value: 'action', class: 'primary lighten-5 fixed', width: 150, align: 'center fixed' })
      //             this.headers = message.data.result.headers
      //           }
      //           this.dataLoading = false
      //         }, error => {
      //           this.dataLoading = false
      //           console.log(error)
      //         })
      //       } else {
      //         this.items = []
      //         this.headers = []
      //       }
      //     }
      //   }
      // )
    },
    getImage (item) {
      this.image = item
      this.imageDialog = true
    },
    getMap (item) {
      const position = item.split(',')
      this.lat = position[0]
      this.long = position[1]
      this.locationDialog = true
    },
    getAlertColor (header, val) {
      if (!header.alert) return ''
      const filter = header.alert.filter(dat => Number(val) >= dat.min && Number(val) <= dat.max)
      if (filter.length === 0) return ''
      return filter[0].color
    },
    deleteItem (item) {
      this.currentItem = item
      this.deleteDialog = true
    },
    buildParam (param, data) {
      let ret = ''
      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (i < 1) {
            ret += param + '=' + data[i]
          } else {
            ret += '&' + param + '=' + data[i]
          }
        }
      }
      return ret
    },
    getHeader (val) {
      // console.log(val)
      // console.log(this.formListItems.filter(val => this.formId.formId.includes(val.formId)))
      const header = { ...val, class: 'primary lighten-5 fixed', ...this.formListItems.filter(val => this.formId.formId.includes(val.formId))[0].schema.fields.filter(dat => dat.name === val.value)[0] }
      return header
    },
    getFormResult () {
      this.dataLoading = true
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (this.formListItems.length > 0) {
        getFormResult({ tenantId: tenant.tenantId, siteId: site.siteId, formTypeId: this.formType, year: this.buildParam('year', [this.year]), name: '', offset: (itemsPerPage * (page - 1)), limit: (itemsPerPage === -1) ? this.totalItems : itemsPerPage, sortBy: sortBy, sortDesc: sortDesc, formId: this.buildParam('formId', this.formId), startDate: this.startDate, endDate: this.endDate }, message => {
          if (message.data.code === 1) {
            this.items = message.data.result.items
            this.totalItems = message.data.result.itemsLength
            message.data.result.headers = message.data.result.headers.map(this.getHeader)
            message.data.result.headers.push({ text: 'ดำเนินการ', value: 'action', class: 'primary lighten-5 fixed', width: 150, align: 'center fixed' })
            this.headers = message.data.result.headers
          }
          this.dataLoading = false
        }, error => {
          this.dataLoading = false
          console.log(error)
        })
      } else {
        this.dataLoading = false
      }
    },
    getFormResultItem (item) {
      this.dataLoading = true
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      getFormResultItem({ tenantId: tenant.tenantId, siteId: site.siteId, formTypeId: this.formType, id: item.id }, message => {
        if (message.data.code === 1) {
          this.form = message.data.result.items[0]
          this.preview = true
        }
        this.dataLoading = false
      }, error => {
        this.dataLoading = false
        console.log(error)
      })
    },
    deleteFormResult () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      this.deleteDialog = false
      deleteFormResult({ tenantId: tenant.tenantId, siteId: site.siteId, formTypeId: this.formType, id: this.currentItem.id }, message => {
        if (message.data.code === 1) {
          this.getFormResult()
        }
      }, error => {
        console.log(error)
      })
    },
    patchFormResult () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      this.preview = false
      patchFormResult({ tenantId: tenant.tenantId, siteId: site.siteId, formTypeId: this.formType, id: this.form.id, etag: this.form._etag }, this.form, message => {
        if (message.data.code === 1) {
          this.getFormResult()
        } else {
          this.getFormResult()
        }
      }, error => {
        console.log(error)
      })
    },
    async fetchData () {
      this.loading = true
      if (this.$auth.role) {
        try {
          const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
          const site = JSON.parse(sessionStorage.getItem('Site'))
          let resp = await getSiteFormType({
            tenantId: tenant.tenantId,
            siteId: site.siteId,
            offset: 0,
            limit: 100,
            sortBy: 'formTypeName',
            sorDesc: 0
          })
          if (resp.data.code === 1) {
            this.formTypeItems = resp.data.result.items
            this.formType = (this.result.formTypeId) ? this.result.formTypeId : ''
          }
          resp = await getYear()
          if (resp.data.code === 1) {
            this.yearItems = resp.data.result
            this.year = (this.result.year) ? String(this.result.year) : [String(new Date().getFullYear())]
            if (this.result.formId) {
              this.formId = [this.result.formId]
              this.formListItems = [this.result]
              this.getFormResult()
            }
          }
          this.getForm()
          // this.applyFooWatcher()
        } catch (error) {
          console.log(error)
        } finally {
          this.loading = false
        }
      }
    }
  },
  created () {
    this.startDate = this.$dayjs().subtract(3, 'month').format('YYYY-MM-DD')
    this.endDate = this.$dayjs().format('YYYY-MM-DD')
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  },
  beforeRouteLeave (to, from, next) {
    this.result = {}
    next()
  }
}
</script>

<style >
table > tbody > tr > td.fixed:nth-last-child(1),
table > thead > tr > th.fixed:nth-last-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  right: 0;
  z-index: 9998;
  background: white;
  -webkit-box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
  box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
}

table > thead > tr > th.fixed:nth-last-child(1) {
  z-index: 9999;
}
</style>
